@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family: Poppins,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";
  font-feature-settings: normal;
}

html::-webkit-scrollbar {
  width: 5px;
}

html::-webkit-scrollbar-track {
  background-color: rgb(0, 0, 0);
}

html::-webkit-scrollbar-thumb {
  background: rgb(51 42 41);
  border-radius: 25px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  margin: 0;
  padding: 0;
  line-height: inherit;
  box-sizing: border-box;
  /* background-color: #1a1c1f; */
}

.apexcharts-tooltip.apexcharts-theme-light, .apexcharts-menu {
  background: #1a1c1f !important;
  border: 1px solid #FF9A00 !important;
}

.apexcharts-menu-item:hover {
  /* background: rgba(26, 28, 31, 0.5) !important; */
  background: rgba(255, 255, 255, 0.2) !important;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: #1a1c1f !important;
}

.wrap{
  min-height: calc(100%-300px);
}
input[type=file]::-webkit-file-upload-button {
  background: linear-gradient(94.02deg, #FFC300 8.31%, #FF9A00 95.06%);
  border: none;
  color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
}